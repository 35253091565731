.block{
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
}


.flex{
  display: flex;

}

#xiaoxiao{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}